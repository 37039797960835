import axios from "axios";
import Loader from "@/components/Loader/Loader.vue";
import moment from 'moment';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Toast from "@/components/Toast/Toast.vue";

export default {
    name: "ReportAccount",
    components: { Loader, Treeselect, Toast },
    data() {
        return {
            isPreviewLoader: false,
            isDataLoader: false,
            isPrevDateRange: true,
            moment: moment,
            dateRange: {
                startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            },
            previewDateRange: { startDate: null, endDate: null },
            intervalsList: [
                {
                    key: "week",
                    label: "Week",
                },
                {
                    key: "biweekly",
                    label: "Bi-Week",
                },
                {
                    key: "month",
                    label: "Month",
                },
                {
                    key: "quarter",
                    label: "Quarter",
                },
                {
                    key: "half_year",
                    label: "Half Year",
                },
                {
                    key: "year",
                    label: "Year",
                },
            ],
            reportName: "",
            channel: [],
            reportData: [],
            allChannelList: [],
            selectedMetrics: [],
            metric: [],
            filteredMetrics: [],
            allPreviewData: [],
            isPreview: false,
            prefixCampaignName: "",
            selectedCampaignName: "CampaignName",
            suffixCampaignName: "",
            selectedInterval: {
                key: "week",
                label: "Week",
            },
            filterBy: "",
            isFilter: false,
            selectedNumericCampaignValue: "",
            numericFiltersFields: [],
            selectedNumericMatricsValue: "",
            matricsInputValue: "",
            selectedStringCampaignValue: "",
            stringFiltersFields: [],
            selectedStringMatricsValue: "",
            StringMatricsInputValue: "",
            storeNumericFilterValue: [],
            manualDateSelected: true,
            selectedReport: null,
            existReportId: null,
            updateReportName: "",
            updateChannels: [],
            updateMetrics: [],
            updateMeta_nomenclature: "",
            updateFilters: [],
            updateSelectedMetrics: [],
            updateMatricsInputValue: "",
            updateStringMatricsInputValue: "",
            storeUpdateNumericFilterValue: [],
            updatePrefixCampaignName: "",
            updateSuffixCampaignName: ""

        }
    },
    mounted() {
        this.initializeModal();
        this.getChannelList();
        this.updateDateRange(this.selectedInterval)
        this.getReportList();
    },
    beforeDestroy() {
        this.hideCreateReportModal();
        this.hideUpdateReportModal();
        this.isPrevConfirmModal.hide();
        this.isDeleteDashboardModal.hide()
    },
    watch: {
        channel(newChannelList) {
            this.updateNumericFilters(newChannelList);
        },
        updateChannels(newChannelList) {
            this.updateNumericFilters(newChannelList);
        }
    },
    methods: {
        updateNumericFilters(newChannelList) {
            const selectedKeys = new Set(newChannelList.map(channel => channel.key));
            const numericFilterLists = this.allChannelList?.filter(list => selectedKeys.has(list.key)).map(list => list.m_filters) || [];
            const stringFilterLists = this.allChannelList?.filter(list => selectedKeys.has(list.key)).map(list => list.p_filters) || [];
            this.numericFiltersFields = {
                fields: [...new Set(numericFilterLists.flatMap(filter => filter?.fields || []))],
                operations: [...new Set(numericFilterLists.flatMap(filter => filter?.operations || []))]
            };
            this.stringFiltersFields = {
                fields: [...new Set(stringFilterLists.flatMap(filter => filter?.fields || []))],
                operations: [...new Set(stringFilterLists.flatMap(filter => filter?.operations || []))]
            };
            this.selectedNumericCampaignValue = this.numericFiltersFields.fields.includes(this.selectedNumericCampaignValue) ? this.selectedNumericCampaignValue : (this.numericFiltersFields.fields[0] || "");
            this.selectedNumericMatricsValue = this.numericFiltersFields.operations.includes(this.selectedNumericMatricsValue) ? this.selectedNumericMatricsValue : (this.numericFiltersFields.operations[0] || "");
            this.selectedStringCampaignValue = this.stringFiltersFields.fields.includes(this.selectedStringCampaignValue) ? this.selectedStringCampaignValue : (this.stringFiltersFields.fields[0] || "");
            this.selectedStringMatricsValue = this.stringFiltersFields.operations.includes(this.selectedStringMatricsValue) ? this.selectedStringMatricsValue : (this.stringFiltersFields.operations[0] || "");
        },
        handleIsFilter() {
            const existingValue = this.storeNumericFilterValue?.find(
                (item) => item[0].metric === this.selectedNumericCampaignValue
            );

            if (existingValue) {
                this.selectedNumericMatricsValue = existingValue[0].operation;
                this.matricsInputValue = existingValue[0].value;
            }
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
            this.isFilter = true;
        },
        handleFilterModal(data, storeFilterValue, selectedMatricsValueKey, matricsInputValueKey) {
            if (data.length <= 0) {
                return false;
            }

            let existingValue = storeFilterValue.find(
                (item) => item[0].metric === data && item[0].operation === this[selectedMatricsValueKey]
            );

            if (existingValue) {
                this[matricsInputValueKey] = existingValue[0].value;
            } else {
                this[matricsInputValueKey] = "";
            }
        },
        handleNumericFilterModal(data) {
            this.handleFilterModal(
                data,
                this.storeNumericFilterValue,
                'selectedNumericMatricsValue',
                'matricsInputValue'
            );
        },
        handleNumericMatricFilterModal(data) {
            let existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].metric === this.selectedNumericCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.matricsInputValue = existingValue[0].value;
            } else {
                this.matricsInputValue = ""
            }
        },

        handleStringFilterModal(data) {
            this.handleFilterModal(
                data,
                this.storeNumericFilterValue,
                'selectedStringMatricsValue',
                'StringMatricsInputValue'
            );
        },

        handleStringMatricsFilterModal(data) {
            let existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].metric === this.selectedStringCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.StringMatricsInputValue = existingValue[0].value;
            } else {
                this.StringMatricsInputValue = ""
            }
        },
        clearFilter() {
            this.isFilter = false;
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
        },
        handlePostFilters({
            selectedCampaignValue,
            selectedMatricsValue,
            matricsInputValue,
            storeFilterValue
        }) {
            let existingFilter = storeFilterValue.find(
                (item) => item[0].metric === selectedCampaignValue && item[0].operation === selectedMatricsValue
            );

            if (existingFilter) {
                // existingFilter[0].operation = selectedMatricsValue;
                existingFilter[0].value = isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue);
            } else {
                storeFilterValue.push([
                    {
                        operation: selectedMatricsValue,
                        metric: selectedCampaignValue,
                        value: isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue)
                    }
                ]);
            }
        },
        postNumericalFilters() {
            this.handlePostFilters({
                selectedCampaignValue: this.selectedNumericCampaignValue,
                selectedMatricsValue: this.selectedNumericMatricsValue,
                matricsInputValue: this.matricsInputValue,
                storeFilterValue: this.storeNumericFilterValue,
            });
            this.handlePreview();
            this.isFilter = false;
        },

        handlePostStringFilters() {
            this.storeNumericFilterValue.push([
                {
                    operation: this.selectedStringMatricsValue,
                    metric: this.selectedStringCampaignValue,
                    value: this.StringMatricsInputValue
                }
            ])
            this.handlePreview();
            this.isFilter = false;
        },
        handleUpdateIsFilter() {
            const existingValue = this.storeUpdateNumericFilterValue[0]?.find(
                (item) => item.metric === this.selectedNumericCampaignValue
            );
            if (existingValue) {
                this.selectedNumericMatricsValue = existingValue.operation;
                this.updateMatricsInputValue = existingValue.value;
            }
            this.updateMatricsInputValue = "";
            this.updateStringMatricsInputValue = "";
            this.isFilter = true;
        },
        handleUpdateFilterModal(data, storeFilterValue, selectedMatricsValueKey, matricsInputValueKey) {
            if (data.length <= 0) {
                return false;
            }

            let existingValue = storeFilterValue[0]?.find(
                (item) => item.metric === data && item.operation === this[selectedMatricsValueKey]
            );
            if (existingValue) {
                this[matricsInputValueKey] = existingValue.value;
            } else {
                this[matricsInputValueKey] = "";
            }
        },
        handleUpdateNumericFilterModal(data) {
            this.handleUpdateFilterModal(
                data,
                this.storeUpdateNumericFilterValue,
                'selectedNumericMatricsValue',
                'updateMatricsInputValue'
            );
        },
        handleUpdateNumericMatricFilterModal(data) {
            let existingValue = this.storeUpdateNumericFilterValue[0]?.find(
                (item) => item.metric === this.selectedNumericCampaignValue && item.operation === data)
            if (existingValue) {
                this.updateMatricsInputValue = existingValue.value;
            } else {
                this.updateMatricsInputValue = ""
            }
        },

        handleUpdateStringFilterModal(data) {
            this.handleUpdateFilterModal(
                data,
                this.storeUpdateNumericFilterValue,
                'selectedStringMatricsValue',
                'updateStringMatricsInputValue'
            );
        },

        handleUpdateStringMatricsFilterModal(data) {
            let existingValue = this.storeUpdateNumericFilterValue[0]?.find(
                (item) => item.metric === this.selectedStringCampaignValue && item.operation === data)
            if (existingValue) {
                this.updateStringMatricsInputValue = existingValue.value;
            } else {
                this.updateStringMatricsInputValue = ""
            }
        },
        clearUpdateFilter() {
            this.isFilter = false;
            this.updateMatricsInputValue = "";
            this.updateStringMatricsInputValue = "";
        },
        handleUpdatePostFilters({
            selectedCampaignValue,
            selectedMatricsValue,
            updateMatricsInputValue,
            storeFilterValue
        }) {
            let existingFilter = storeFilterValue[0]?.find(
                (item) => item.metric === selectedCampaignValue && item.operation === selectedMatricsValue
            );

            if (existingFilter) {
                // existingFilter.operation = selectedMatricsValue;
                existingFilter.value = isNaN(updateMatricsInputValue) ? updateMatricsInputValue : parseFloat(updateMatricsInputValue);
            } else if (storeFilterValue.length) {
                storeFilterValue[0].push({
                    operation: selectedMatricsValue,
                    metric: selectedCampaignValue,
                    value: isNaN(updateMatricsInputValue) ? updateMatricsInputValue : parseFloat(updateMatricsInputValue)
                });
            } else {
                storeFilterValue.push([{
                    operation: selectedMatricsValue,
                    metric: selectedCampaignValue,
                    value: isNaN(updateMatricsInputValue) ? updateMatricsInputValue : parseFloat(updateMatricsInputValue)
                }]);
            }
        },
        postUpdateNumericalFilters() {
            this.handleUpdatePostFilters({
                selectedCampaignValue: this.selectedNumericCampaignValue,
                selectedMatricsValue: this.selectedNumericMatricsValue,
                updateMatricsInputValue: this.updateMatricsInputValue,
                storeFilterValue: this.storeUpdateNumericFilterValue,
            });
            // this.handlePreview();
            this.isFilter = false;
        },

        handlePostStringUpdateFilters() {
            if (this.storeUpdateNumericFilterValue.length) {
                this.storeUpdateNumericFilterValue[0].push({
                    operation: this.selectedStringMatricsValue,
                    metric: this.selectedStringCampaignValue,
                    value: this.updateStringMatricsInputValue
                })
            } else {
                this.storeUpdateNumericFilterValue.push([{
                    operation: this.selectedStringMatricsValue,
                    metric: this.selectedStringCampaignValue,
                    value: this.updateStringMatricsInputValue
                }])
            }

            this.isFilter = false;
        },
        RemoveBadgeValue(data) {
            const index = this.storeNumericFilterValue.flat().findIndex(
                (item) =>
                    item.metric === data.metric &&
                    item.operation === data.operation &&
                    item.value === data.value
            );

            if (index !== -1) {
                this.storeNumericFilterValue.splice(index, 1);
            }
            this.handlePreview();
        },
        RemoveAllBadgeValue() {
            this.storeNumericFilterValue = [];
            this.handlePreview();
        },
        RemoveUpdateBadgeValue(data) {
            this.storeUpdateNumericFilterValue = this.storeUpdateNumericFilterValue.map(group =>
                group.filter(item =>
                    !(item.metric === data.metric &&
                        item.operation === data.operation &&
                        item.value === data.value)
                )
            );
        },
        RemoveAllUpdateBadgeValue() {
            this.storeUpdateNumericFilterValue = [];
        },
        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format(
                "YYYY-MM-DD"
            );
            this.dateRange.endDate = moment(this.dateRange.endDate).format(
                "YYYY-MM-DD"
            );
        },
        formatPreviewDateRange() {
            this.previewDateRange.startDate = moment(this.previewDateRange.startDate).format(
                "YYYY-MM-DD"
            );
            this.previewDateRange.endDate = moment(this.previewDateRange.endDate).format(
                "YYYY-MM-DD"
            );
        },

        formatDates() {
            this.formatDateRange();
            const id = localStorage.getItem('reportId')
            this.handlePreview({ report_id: id });
        },

        formatPreviewDates() {
            this.formatPreviewDateRange();
            this.manualDateSelected = true;
            this.handlePreview();
        },

        createReportModal() {
            this.initializeModal();
            this.reportName = "";
            this.channel = null;
            this.metric = null;
            this.prefixCampaignName = "";
            this.suffixCampaignName = "";
            this.storeNumericFilterValue = [];
            this.filteredMetrics = [];
            this.accountReportModal.show();
        },

        hideCreateReportModal() {
            this.accountReportModal.hide();
        },

        initializeModal() {
            const $targetReportModal = document.getElementById("report-modal");
            const reportModal = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
            };

            const $deleteModal = document.getElementById("modalDelete");
            const deleteModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: false,
            };

            const $updateModal = document.getElementById("updateReport-modal");
            const updateModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: false,
            };
            const $prevConfirmModal = document.getElementById("prevConfirm-modal");
            const prevModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: false,
            };
            this.isUpdateReportModal = new Modal($updateModal, updateModalOptions);
            this.isPrevConfirmModal = new Modal($prevConfirmModal, prevModalOptions);
            this.isDeleteDashboardModal = new Modal($deleteModal, deleteModalOptions);
            this.accountReportModal = new Modal($targetReportModal, reportModal);
        },

        openDeleteModal(data) {
            this.initializeModal();
            this.selectedReport = data;
            this.isDeleteDashboardModal.show()
        },

        openUpdateModal(data) {
            let selectedMetricsMap = new Map();
            let uniqueKeys = new Set();
            this.initializeModal();
            this.existReportId = data.report_id;
            this.updateReportName = data.report_name;
            this.updateMetrics = data.metrics;
            this.storeUpdateNumericFilterValue = [data.filters];
            this.updateChannels = data.channels?.map((key) => this.allChannelList.find((channel) => channel.key === key)).filter(Boolean);
            this.filteredMetrics = [];
            const [prefix, suffix] = data.meta_nomenclature.split('CampaignName').map(item => item.trim());
            this.updatePrefixCampaignName = prefix;
            this.updateSuffixCampaignName = suffix;

            this.updateChannels.forEach(ch => {
                let selectedChannel = this.allChannelList.find(item => item.key === ch.key);
                if (selectedChannel && !uniqueKeys.has(selectedChannel.key)) {
                    uniqueKeys.add(selectedChannel.key);

                    this.filteredMetrics.push({
                        id: selectedChannel.key,
                        label: selectedChannel.value,
                        children: selectedChannel.metrics.map(metric => ({
                            id: metric.key,
                            label: metric.value
                        }))
                    });

                    if (!selectedMetricsMap.has(selectedChannel.key)) {
                        selectedMetricsMap.set(selectedChannel.key, {
                            updateChannels: selectedChannel.value,
                            metrics: []
                        });
                    }

                    selectedChannel.selectedMetrics.forEach(metric => {
                        selectedMetricsMap.get(selectedChannel.key).metrics.push(metric);
                    });
                }
            });
            this.isUpdateReportModal.show();
        },

        hideUpdateReportModal() {
            this.isUpdateReportModal.hide()
        },
        hidePrevConfirmModal() {
            this.isPrevConfirmModal.hide()
        },
        getIcon(val) {
            const imageMapper = {
                ga4: "Google Analytics",
                adword: "Google Ads",
                meta: "meta",
                amazon: "Amazon",
                flipkart: "Flipkart",
            }
            return require(`@/assets/Icons_SVG/${imageMapper[val]}.svg`);
        },

        setInterval(interval) {
            this.selectedInterval = interval;
            this.updateDateRange(interval.key);
        },
        setPrevInterval(interval) {
            this.selectedInterval = interval;
            this.updateDateRange(interval.key);
            this.manualDateSelected = false;
            this.handlePreview();
        },
        updateDateRange(intervalKey) {
            let startDate = moment();
            let endDate = moment();

            switch (intervalKey) {
                case "week":
                    startDate = moment().subtract(1, "week");
                    endDate = moment();
                    break;
                case "biweekly":
                    startDate = moment().subtract(2, "week");
                    endDate = moment();
                    break;
                case "month":
                    startDate = moment().subtract(1, "month");
                    endDate = moment();
                    break;
                case "quarter":
                    startDate = moment().subtract(1, "quarter");
                    endDate = moment();
                    break;
                case "half_year":
                    startDate = moment().subtract(6, "month");
                    endDate = moment();
                    break;
                case "year":
                    startDate = moment().subtract(1, "year");
                    endDate = moment();
                    break;
            }

            this.previewDateRange = { startDate: startDate.format("DD MMM YYYY"), endDate: endDate.format("DD MMM YYYY") };
        },

        handleBack() {
            this.getReportList();
            this.isPreview = false;
        },
        destroyDataTable(id) {
            if ($.fn.DataTable.isDataTable(id)) {
                $(id).DataTable().clear().destroy();
            }
        },
        processTable(id) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: true,
                ordering: true,
                // lengthMenu: [20, 50, 100],
                initComplete: function () {
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
        },

        handleSelectChannel() {
            let selectedMetricsMap = new Map();
            let filteredMetrics = [];


            const adwordKey = "adword";
            const adwordSelected = this.channel.some(ch => ch.key === adwordKey);

            if (adwordSelected) {
                if (this.channel.length > 2) {
                    this.$toast.error("You can select 'Google Adword' only with one other channel.", {
                        position: "top-center",
                        timeout: 2000,
                    });
                    this.channel = this.channel.filter(ch => ch.key === adwordKey || ch === this.channel[this.channel.length - 1]);
                }
            }

            this.channel.forEach(ch => {
                let selectedChannel = this.allChannelList.find(item => item.key === ch.key);
                if (selectedChannel) {
                    filteredMetrics.push({
                        id: selectedChannel.key,
                        label: selectedChannel.value,
                        children: selectedChannel.metrics.map(metric => ({
                            id: metric.key,
                            label: metric.value
                        }))
                    });

                    if (!selectedMetricsMap.has(selectedChannel.key)) {
                        selectedMetricsMap.set(selectedChannel.key, {
                            channel: selectedChannel.value,
                            metrics: []
                        });
                    }

                    selectedChannel.selectedMetrics.forEach(metric => {
                        selectedMetricsMap.get(selectedChannel.key).metrics.push(metric);
                    });
                }
            });

            this.filteredMetrics = filteredMetrics;
            this.selectedMetrics = Array.from(selectedMetricsMap.values()); // Convert Map to Array
            this.metric = this.selectedMetrics.flatMap(group => group.metrics.map(m => m.key));
        },

        handleUpdateSelectChannel() {
            let selectedMetricsMap = new Map();
            let filteredMetrics = [];


            const adwordKey = "adword";
            const adwordSelected = this.updateChannels.some(ch => ch.key === adwordKey);

            if (adwordSelected) {
                if (this.updateChannels.length > 2) {
                    this.$toast.error("You can select 'Google Adword' only with one other Channels.", {
                        position: "top-center",
                        timeout: 2000,
                    });
                    this.updateChannels = this.updateChannels.filter(ch => ch.key === adwordKey || ch === this.updateChannels[this.updateChannels.length - 1]);
                }
            }

            const previousChannels = new Set(this.filteredMetrics.map(item => item.id));

            this.updateChannels.forEach(ch => {
                let selectedChannel = this.allChannelList.find(item => item.key === ch.key);
                if (selectedChannel) {
                    filteredMetrics.push({
                        id: selectedChannel.key,
                        label: selectedChannel.value,
                        children: selectedChannel.metrics.map(metric => ({
                            id: metric.key,
                            label: metric.value
                        }))
                    });

                    if (!selectedMetricsMap.has(selectedChannel.key)) {
                        selectedMetricsMap.set(selectedChannel.key, {
                            updateChannels: selectedChannel.value,
                            metrics: []
                        });
                    }

                    selectedChannel.selectedMetrics.forEach(metric => {
                        selectedMetricsMap.get(selectedChannel.key).metrics.push(metric);
                    });
                }
            });

            const removedChannels = [...previousChannels].filter(
                channelKey => !this.updateChannels.some(ch => ch.key === channelKey)
            );

            this.updateMetrics = this.updateMetrics.filter(metricKey => {
                return !removedChannels.some(channelKey => {
                    let removedChannel = this.allChannelList.find(item => item.key === channelKey);
                    return removedChannel?.selectedMetrics.some(m => m.key === metricKey);
                });
            });

            this.filteredMetrics = filteredMetrics;
            // this.updateMetrics = this.updateSelectedMetrics.flatMap(group => group.metrics.map(m => m.key));
        },

        handleSelectedMetrics(selectedKeys) {
            let allAvailableMetrics = this.filteredMetrics.flatMap(channel => ({
                channel: channel.label,
                channelId: channel.id,
                metrics: channel.children
            }));

            // Create a new grouped structure
            let groupedMetricsMap = new Map();

            selectedKeys.forEach(key => {
                let metricObj = allAvailableMetrics.find(group =>
                    group.metrics.some(metric => metric.id === key)
                );

                if (metricObj) {
                    if (!groupedMetricsMap.has(metricObj.channelId)) {
                        groupedMetricsMap.set(metricObj.channelId, {
                            channel: metricObj.channel,
                            metrics: []
                        });
                    }

                    let metric = metricObj.metrics.find(m => m.id === key);
                    groupedMetricsMap.get(metricObj.channelId).metrics.push(metric);
                }
            });

            this.selectedMetrics = Array.from(groupedMetricsMap.values());
        },
        handleUpdateSelectedMetrics(selectedKeys) {
            let allAvailableMetrics = this.filteredMetrics.flatMap(channel => ({
                channel: channel.label,
                channelId: channel.id,
                metrics: channel.children
            }));

            // Create a new grouped structure
            let groupedMetricsMap = new Map();

            selectedKeys.forEach(key => {
                let metricObj = allAvailableMetrics.find(group =>
                    group.metrics.some(metric => metric.id === key)
                );

                if (metricObj) {
                    if (!groupedMetricsMap.has(metricObj.channelId)) {
                        groupedMetricsMap.set(metricObj.channelId, {
                            channel: metricObj.channel,
                            metrics: []
                        });
                    }

                    let metric = metricObj.metrics.find(m => m.id === key);
                    groupedMetricsMap.get(metricObj.channelId).metrics.push(metric);
                }
            });

            this.updateSelectedMetrics = Array.from(groupedMetricsMap.values());
        },

        removeMetric(channel, key) {
            this.selectedMetrics = this.selectedMetrics.map(group => {
                if (group.channel === channel) {
                    group.metrics = group.metrics.filter(metric => metric.id !== key);
                }
                return group;
            }).filter(group => group.metrics.length > 0);

            this.metric = this.selectedMetrics.flatMap(group => group.metrics.map(m => m.id));
        },
        removeUpdateMetric(channel, key) {
            this.updateSelectedMetrics = this.updateSelectedMetrics.map(group => {
                if (group.channel === channel) {
                    group.metrics = group.metrics.filter(metric => metric.id !== key);
                }
                return group;
            }).filter(group => group.metrics.length > 0);

            this.updateMetrics = this.updateSelectedMetrics.flatMap(group => group.metrics.map(m => m.id));
        },

        clearAllMetrics() {
            this.selectedMetrics = [];
            this.metric = [];
        },
        clearAllUpdateMetrics() {
            this.updateSelectedMetrics = [];
            this.updateMetrics = [];
        },

        // modal dropdown list values
        getChannelList() {
            const url = "/api/account/report/defaults";
            const payload = {};

            axios.post(url, payload).then((res) => {
                this.allChannelList = res.data.data.channel;
                // this.numericFiltersFields = res.data.data.m_filters;

            }).catch(err => {
                console.error(err);
                // this.$toast.error(err, {
                //     position: "top-center",
                //     timeout: 2000,
                // });
            })
        },

        getReportList() {
            const url = "/api/account/report/list";
            const payload = {};
            this.reportData = [];
            this.isDataLoader = true;

            axios.post(url, payload).then((res) => {
                this.reportData = res.data.data;
                this.isDataLoader = false;
                this.$nextTick(() => {
                    this.processTable("#accReportTable-tbl");
                });
            }).catch(err => {
                console.error(err);
                this.isDataLoader = false;
                // this.$toast.error(err, {
                //     position: "top-center",
                //     timeout: 2000,
                // });
            })
        },
        confirmPrevModal() {
            if (!this.reportName || !this.channel.length || !this.metric.length) {
                this.$toast.error("Please fill all fields.", {
                    position: "top-center",
                    timeout: 2000,
                });
                return;
            }
            const requiresNomenclature = this.channel.some(list => list.key === "ga4") && this.channel.some(list => list.key === "meta");
            if (requiresNomenclature) {
                if (!this.prefixCampaignName && !this.suffixCampaignName) {
                    this.isPrevConfirmModal.show();
                    // this.hideCreateReportModal();
                    return false;
                }
            }
            this.handlePreview();
        },
        confirmWithoutNomenclature() {
            this.handlePreview()
        },
        handlePreview(data) {
            if (!data?.report_id && (!this.reportName || !this.channel.length || !this.metric.length)) {
                this.$toast.error("Please fill the all fields.", {
                    position: "top-center",
                    timeout: 2000,
                });
                return false;
            }

            const url = "/api/account/report/view";
            this.isPreview = true;
            this.isPreviewLoader = true;
            this.hideCreateReportModal()
            this.hidePrevConfirmModal()
            this.updateDateRange(this.selectedInterval.key);
            localStorage.setItem("reportId", JSON.stringify(data?.report_id))

            if (data?.report_id) {
                this.isPrevDateRange = false;
            } else {
                this.isPrevDateRange = true;
            }

            const payload = data?.report_id ? {
                "report_id": data?.report_id,
                "start_date": moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                "end_date": moment(this.dateRange.endDate).format("YYYY-MM-DD"),
            } : {
                "report_name": this.reportName,
                "channels": this.channel.map((data) => { return data.key }),
                "metrics": this.metric,
                "filters": this.storeNumericFilterValue.flat(),
                "meta_nomenclature": (this.prefixCampaignName || this.suffixCampaignName) ? (this.prefixCampaignName + this.selectedCampaignName + this.suffixCampaignName) : this.selectedCampaignName,
                "interval": this.manualDateSelected ? null : this.selectedInterval?.key,
                "start_date": moment(this.previewDateRange.startDate).format("YYYY-MM-DD"),
                "end_date": moment(this.previewDateRange.endDate).format("YYYY-MM-DD"),
            }

            axios.post(url, payload).then((res) => {
                this.allPreviewData = res.data.data;
                this.isPreviewLoader = false;
                this.$nextTick(() => {
                    this.processTable("#previewReportTable-tbl");
                });
            }).catch((err) => {
                console.error(err);
                this.$toast.error(err, {
                    position: "top-center",
                    timeout: 2000,
                });
                this.isPreviewLoader = false;
            })
        },
        handleSinglePreview(data) {
            this.isPrevDateRange = false;
            this.handlePreview(data)
        },

        handleCreateReport() {
            const url = "/api/account/report/create";

            const payload = {
                "report_name": this.reportName,
                "channels": this.channel.map((data) => { return data.key }),
                "metrics": this.metric,
                "filters": this.storeNumericFilterValue.flat(),
                "interval": this.manualDateSelected ? null : this.selectedInterval?.key,
                "start_date": moment(this.previewDateRange.startDate).format("YYYY-MM-DD"),
                "end_date": moment(this.previewDateRange.endDate).format("YYYY-MM-DD"),
                "meta_nomenclature": (this.prefixCampaignName || this.suffixCampaignName) ? (this.prefixCampaignName + this.selectedCampaignName + this.suffixCampaignName) : this.selectedCampaignName,
            }

            axios.post(url, payload).then((res) => {
                this.$toast.success("Report Created Successfully", {
                    position: "top-center",
                    timeout: 2000,
                });
                this.isPreview = false;
                this.destroyDataTable("#accReportTable-tbl");
                this.getReportList();
            }).catch(err => {
                console.error(err);
                this.$toast.error(err, {
                    position: "top-center",
                    timeout: 2000,
                });
            })
        },
        handleUpdateReport() {
            if (!this.updateReportName || !this.updateChannels.length || !this.updateMetrics.length) {
                this.$toast.error("Please fill the all fields.", {
                    position: "top-center",
                    timeout: 2000,
                });
                return false;
            }
            const url = "/api/account/report/update";

            const payload = {
                "report_id": this.existReportId,
                "report_name": this.updateReportName,
                "channels": this.updateChannels.map((data) => { return data.key }),
                "metrics": this.updateMetrics,
                "filters": this.storeUpdateNumericFilterValue.flat(),
                "meta_nomenclature": (this.updatePrefixCampaignName || this.updateSuffixCampaignName) ? (this.updatePrefixCampaignName + this.selectedCampaignName + this.updateSuffixCampaignName) : this.selectedCampaignName,
            }

            axios.post(url, payload).then((res) => {
                this.$toast.success("Report Updated Successfully", {
                    position: "top-center",
                    timeout: 2000,
                });
                this.isPreview = false;
                this.destroyDataTable("#accReportTable-tbl");
                this.getReportList();
                this.hideUpdateReportModal();
            }).catch(err => {
                console.error(err);
                this.$toast.error(err, {
                    position: "top-center",
                    timeout: 2000,
                });
            })
        },
        handleDeleteReport(data) {
            if (!data.report_id) {
                return false;
            }
            const url = "/api/account/report/delete";
            const payload = {
                report_id: [data.report_id]
            }

            axios.post(url, payload).then((res) => {
                this.destroyDataTable("#accReportTable-tbl");
                this.getReportList();
                this.$toast.success("Report Deleted Successfully", {
                    position: "top-center",
                    timeout: 2000,
                });
                this.isDeleteDashboardModal.hide()
            }).catch((err) => {
                console.error(err)
                this.$toast.error("Error", {
                    position: "top-center",
                    timeout: 2000,
                });
            })
        }
    },
}